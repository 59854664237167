<template>
    <v-layout>
        <v-footer color="black">
            <v-row justify="center" no-gutters>
                <v-col class="text-center mt-4" cols="12">
                    <strong>DPSG Stamm Heilig Geist</strong>
                </v-col>
            </v-row>
        </v-footer>
    </v-layout>
</template>
