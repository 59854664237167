<template>
  <v-card color="black">
    <v-card-title>{{ title }}</v-card-title>
  </v-card>
</template>

<script>
export default {
  props: {
    title: { String },
  },
};
</script>

<style scoped>
.v-card-title {
    white-space: normal;
    text-align: center;
}
</style>