<template>
  <v-layout>
    <v-app-bar color="black" density="compact">
      <template v-slot:prepend>
        <v-app-bar-nav-icon @click="$router.push('/')"></v-app-bar-nav-icon>
      </template>
      <v-tabs v-if="screenWidth > 750">
        <router-link to="/"><v-tab>Home</v-tab></router-link>
        <router-link to="/stufen">
          <v-tab>Stufen</v-tab>
        </router-link>
        <router-link to="/team"><v-tab>Leitungsteam</v-tab></router-link>
        <router-link to="/mitmachen"><v-tab>Mitmachen</v-tab></router-link>
        <router-link to="/kontakt"><v-tab>Kontakt</v-tab></router-link>
        <router-link to="/impressum"><v-tab>Impressum</v-tab></router-link>
      </v-tabs>
      <template v-slot:append v-if="screenWidth <= 750">
        <v-btn icon="mdi-dots-vertical" @click="drawer = !drawer"></v-btn>
      </template>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      temporary
      location="right"
      theme="dark"
    >
      <v-list>
        <v-list-item
          title="Home"
          value="/"
          @click="$router.push('/')"
        ></v-list-item>
        <v-divider></v-divider>
        <v-list-item
          title="Stufen"
          value="/stufen"
          @click="$router.push('/stufen')"
        ></v-list-item>
        <v-divider></v-divider>
        <v-list-item
          title="Leitungsteam"
          value="/team"
          @click="$router.push('/team')"
        ></v-list-item>
        <v-divider></v-divider>
        <v-list-item
          title="Mitmachen"
          value="/mitmachen"
          @click="$router.push('/mitmachen')"
        ></v-list-item>
        <v-divider></v-divider>
        <v-list-item
          title="Kontakt"
          value="/kontakt"
          @click="$router.push('/kontakt')"
        ></v-list-item>
        <v-divider></v-divider>
        <v-list-item
          title="Impressum"
          value="/impressum"
          @click="$router.push('/impressum')"
        ></v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-layout>
</template>

<style scoped>
a {
  color: white;
  text-decoration: none;
}
#app .v-app-bar-nav-icon {
  content: url("@/assets/HeiGeiLogo.svg");
}
</style>

<script>
export default {
  data() {
    return {
      screenWidth: screen.width,
      drawer: false,
    };
  },
};
</script>
